var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"approve-list-content"},[_c('ul',_vm._l((_vm.approveList),function(item,index){return _c('li',{key:index,staticClass:"approve-list-item",class:{
        mt50: index !== _vm.approveList.length - 1
      }},[_c('div',{staticClass:"header-text"},[_vm._v("\n        "+_vm._s(item.nodeType == "PENDING"
            ? "审批"
            : item.fsUserName[0] && item.fsUserName[0].slice(-2))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"gray-text item-text"},[_vm._v(_vm._s(item.nodeName))]),_vm._v(" "),_c('div',{staticClass:"item-text node-content"},[_c('div',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
              item.nodeType != 'PENDING' ||
                (item.nodeType == 'PENDING' && item.fsUserName.length == 1)
            ),expression:"\n              item.nodeType != 'PENDING' ||\n                (item.nodeType == 'PENDING' && item.fsUserName.length == 1)\n            "}],staticClass:"fl"},[_vm._v(_vm._s(item.fsUserName[0]))]),_vm._v(" "),_c('span',{staticClass:"fl yellow-text"},[_vm._v(_vm._s(item.nodeTypeStr))])]),_vm._v(" "),_c('span',{staticClass:"gray-text fl"},[_vm._v("\n          "+_vm._s(_vm.getOperateDate(item.endTime))+"\n        ")])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.nodeType == 'PENDING'),expression:"item.nodeType == 'PENDING'"}],staticClass:"audit-reason"},[_c('span',{staticClass:"gray-text"},[_vm._v("\n          "+_vm._s(item.approveType === "OR" ? "1人通过即可" : "需所有审批人同意")+"\n        ")])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.reason),expression:"item.reason"}],staticClass:"audit-reason"},[_c('span',{staticClass:"gray-text"},[_vm._v("\n          "+_vm._s(item.reason)+"\n        ")])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          item.nodeType === 'PENDING' &&
            item.fsUserName.length &&
            item.fsUserName.length > 1
        ),expression:"\n          item.nodeType === 'PENDING' &&\n            item.fsUserName.length &&\n            item.fsUserName.length > 1\n        "}],staticClass:"audit-user-list"},_vm._l((item.fsUserName),function(item,index){return _c('div',{key:index,staticClass:"user-avatar-box"},[_c('div',{staticClass:"user-avatar"},[_vm._v(_vm._s(item.slice(-2)))]),_vm._v(" "),_c('span',{staticClass:"gray-text"},[_vm._v("\n            "+_vm._s(item)+"\n          ")])])}),0)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }