<!--
 * @Description: 审批详情
 * @Author: LiangYiNing
 * @Date: 2022-01-11 11:12:45
 * @LastEditTime: 2022-07-25 14:53:49
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <section class="edit-wrapper">
      <div class="edit-wrapper__body">
        <div class="detail-content">
          <!-- <customer-hand-over-detail /> -->
          <component :is="componentId"></component>
        </div>
        <div class="approve-list-content">
          <div class="edit-title">审批信息</div>
          <approve-list :approveList="approveList" />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import base from "@/mixins/base";
// 各对应的详情的组件路径 放在detailsComponents 或对应的模块中
// 客户移交详情
import customerHandOverDetail from "@/views/OMS/customerManage/customerHandOver/detail";
// 客户详情
import customerDetail from "@/views/OMS/customerManage/customerList/add/add";
// 开票信息更改详情
import invoiceChangeDetails from "@/views/auditList/detailsComponents/invoiceChangeDetails";
// 客户信息更改详情
import costomerInfoChangeDetails from "@/views/auditList/detailsComponents/costomerInfoChangeDetails";

import approveList from "@/components/approveList/approveList";
import { printError } from "@/utils/util";
import { getApprovalRecordList } from "@/api";
export default {
  name: "approveDetails",
  components: {
    invoiceChangeDetails,
    costomerInfoChangeDetails,
    customerDetail,
    customerHandOverDetail,
    approveList
  },
  mixins: [base],
  data() {
    return {
      loding: true,
      approvalBusinessCode: "",
      approvalBusinessId: "",
      componentId: "", // 审批详情组件ID
      approveList: [] // 审批节点列表
    };
  },
  computed: {},
  created() {
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.id = hasParamsRoute.params.approveId;
      this.approvalBusinessCode = hasParamsRoute.params.approvalBusinessCode;
      this.componentId = this.getComponentId();
      this.approvalBusinessId = hasParamsRoute.params.approvalBusinessId;
      if (this.id) {
        this.getApprovalRecordList();
      }
    },
    // 获取当前组件
    getComponentId() {
      switch (this.approvalBusinessCode * 1) {
        case 1:
          return customerDetail;
        case 2:
          return customerHandOverDetail;
        case 5:
          return invoiceChangeDetails;
        case 6:
          return costomerInfoChangeDetails;
        default:
          return "";
      }
    },
    // 获取审批列表
    async getApprovalRecordList() {
      try {
        this.loading = true;
        let res = await getApprovalRecordList({
          id: this.id
        });
        if (res.code === "OK") {
          this.approveList = this.getFreezeResponse(res, {
            path: "data"
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.content-view-wrapper {
  overflow: hidden;
}
.detail-content {
  display: inline-block;
  width: calc(100% - 340px);
  height: calc(100% - 10px);
  position: relative;
  /deep/.content-view-wrapper {
    height: 100%;
    padding: 0;
    // .edit-wrapper__body {
    //   height: 100%;
    // }
  }
}
.edit-wrapper > .edit-wrapper__body {
  height: 100%;
}
.approve-list-content {
  background: #fff;
  display: inline-block;
  width: 335px;
  position: relative;
  height: calc(100% - 30px);
  vertical-align: top;
  .edit-title {
    margin-bottom: 10px;
  }
}
</style>
