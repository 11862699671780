<!--
 * @Description: 
 * @Author: LiangYiNing
 * @Date: 2021-12-31 11:20:42
 * @LastEditTime: 2022-08-02 18:17:00
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="approve-list-content">
    <ul>
      <li
        class="approve-list-item"
        v-for="(item, index) in approveList"
        :key="index"
        :class="{
          mt50: index !== approveList.length - 1
        }"
      >
        <div class="header-text">
          {{
            item.nodeType == "PENDING"
              ? "审批"
              : item.fsUserName[0] && item.fsUserName[0].slice(-2)
          }}
        </div>
        <div class="gray-text item-text">{{ item.nodeName }}</div>
        <div class="item-text node-content">
          <div>
            <span
              class="fl"
              v-show="
                item.nodeType != 'PENDING' ||
                  (item.nodeType == 'PENDING' && item.fsUserName.length == 1)
              "
              >{{ item.fsUserName[0] }}</span
            >
            <span class="fl yellow-text">{{ item.nodeTypeStr }}</span>
          </div>
          <span class="gray-text fl">
            {{ getOperateDate(item.endTime) }}
          </span>
        </div>
        <div class="audit-reason" v-show="item.nodeType == 'PENDING'">
          <span class="gray-text">
            {{ item.approveType === "OR" ? "1人通过即可" : "需所有审批人同意" }}
          </span>
        </div>
        <div class="audit-reason" v-show="item.reason">
          <span class="gray-text">
            {{ item.reason }}
          </span>
        </div>
        <div
          class="audit-user-list"
          v-show="
            item.nodeType === 'PENDING' &&
              item.fsUserName.length &&
              item.fsUserName.length > 1
          "
        >
          <div
            class="user-avatar-box"
            v-for="(item, index) in item.fsUserName"
            :key="index"
          >
            <div class="user-avatar">{{ item.slice(-2) }}</div>
            <span class="gray-text">
              {{ item }}
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "approveList",
  data() {
    return {};
  },
  props: {
    approveList: {
      type: Array
    }
  },
  methods: {
    getOperateDate(val) {
      if (val && val.length === 19) {
        return val.slice(5, -3);
      } else {
        return val;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
.approve-list-content {
  height: 100%;
  width: 100%;
  overflow: scroll;
  position: relative;
  font-size: 14px;
  .mt50 {
    margin-bottom: 50px;
    &::before {
      content: "";
      display: block;
      height: calc(100% + 50px);
      width: 2px;
      background: rgb(233, 236, 240);
      position: absolute;
      top: 0px;
      left: 35px;
    }
  }
  .audit-reason {
    margin-right: 10px;
  }
}
.gray-text {
  border: none;
  color: rgb(137, 140, 145);
  font-size: 12px;
  word-break: break-all;
}
.red-text {
  color: $--color-status--assist-3;
}
.green-text {
  color: $--color-status--assist-2;
}
.yellow-text {
  color: $--color-status--assist-1;
}
.node-content {
  display: flex;
  justify-content: space-between;
}
.item-text {
  overflow: hidden;
  span {
    margin-right: 10px;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
}
.audit-user-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .user-avatar-box {
    height: 30px;
    display: inline-block;
    line-height: 30px;
    background-color: #f5f7fa;
    border-radius: 20px;
    padding-right: 8px;
    box-sizing: border-box;
    margin-right: 8px;
    margin-top: 5px;
    .user-avatar {
      display: inline-block;
      height: 26px;
      width: 26px;
      font-size: 10px;
      line-height: 26px;
      text-align: center;
      box-sizing: border-box;
      border-radius: 50%;
      background-color: #46a1fd;
      color: white;
    }
  }
}
.approve-list-item {
  padding-left: 80px;
  line-height: 30px;
  position: relative;
}
.header-text {
  position: absolute;
  left: 10px;
  top: 5;
  z-index: 99;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  background-color: #46a1fd;
  font-size: 16px;
  color: white;
}
</style>
